<template>
  <div class="search-container d-flex align-items-center">
    <b-form-input :placeholder="$t('forms.search')" class="search" @input="onInputChange"></b-form-input>
  </div>
</template>

<script>

export default {
  data() {
    return {
      timeout: null,
      inputValue: '',
    }
  },
  name: 'Search',
  methods: {
    onInputChange(value) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.inputValue = ''
        if(value.length > 2) {
          this.inputValue = value
        }

        this.$emit('inputChange', this.inputValue)
      }, 500)
    },
  },
}
</script>
