<template>
  <div class="table-actions">
    <div
      v-if="actions.includes('perPage')"
      class="table-actions__perPage d-flex align-items-center me-auto"
    >
      <label class="me-2">{{ $t("messages.show") }}</label>
      <v-select
        :clearable="false"
        taggable
        v-model="paginationOption"
        :options="paginationOptions"
        @option:selected="perPageChange"
      />
      <span v-if="entries">
        of {{ entries }} entries
      </span>
    </div>
    <div
      v-if="actions.includes('filterSelect')"
      class="table-actions__select"
    >
      <v-select
        :clearable="false"
        v-model="filterOption"
        :options="filterOptions"
        @option:selected="filterSelectChange"
      />
    </div>
    <div v-if="actions.includes('search')" class="table-actions__search">
      <search @inputChange="searchInput" />
    </div>
    <div v-if="actions.includes('columnsBtn')" class="table-actions__addBtn">
      <b-button
        class="ms-4 d-flex align-items-center"
        variant="gray"
        @click="columnsChange"
      >
        {{ $t("forms.showColumns") }}
      </b-button>
    </div>
    <div v-if="actions.includes('advancedFilter')" class="table-actions__addBtn">
      <b-button
        class="ms-4 d-flex align-items-center"
        variant="gray"
        @click="filterChange"
      >
        {{ $t("forms.advancedFilter") }}
      </b-button>
    </div>
    <div v-if="actions.includes('actionBtn')" class="table-actions__addBtn">
      <b-button
        class="ms-4 d-flex align-items-center"
        variant="primary"
        @click="actionBtnClicked"
        :disabled="actionBtnDisabled"
      >
        <BootstrapIcon v-if="actionBtnIcon" :icon="actionBtnIcon" size="1x" />
        {{ actionBtnText }}
      </b-button>
    </div>
    <div v-if="actions.includes('secondaryBtn')" class="table-actions__addBtn">
      <b-button
        :to="secondaryBtnLink"
        class="ms-4 d-flex align-items-center"
        variant="gray"
      >
        <img class="me-2" src="@/assets/images/icons/plus.svg" alt="plus" />
        {{ secondaryBtnText }}
      </b-button>
    </div>
    <div v-if="actions.includes('addBtn')" class="table-actions__addBtn">
      <b-button
        :to="addBtnLink"
        class="ms-4 d-flex align-items-center"
        variant="primary"
      >
        <BootstrapIcon v-if="addBtnIcon" :icon="addBtnIcon" size="1x" />
        <img v-else class="me-2" src="@/assets/images/icons/plus.svg" alt="plus" />
        {{ addBtnText }}
      </b-button>
    </div>

  </div>
</template>

<script>
import Search from "@/components/Inputs/Search.vue";
import vSelect from "vue-select";

export default {
  components: {
    Search,
    vSelect,
  },
  props: {
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
    actionBtnIcon: { type: String, default: null },
    actionBtnText: { type: String, default: "" },
    actionBtnDisabled: { type: Boolean, default: false },
    addBtnIcon: { type: String, default: null },
    addBtnText: { type: String, default: "" },
    addBtnLink: { type: String, default: "" },
    secondaryBtnText: { type: String, default: "" },
    secondaryBtnLink: { type: String, default: "" },
    defaultPerPage: { type: Number, default: 15 },
    option: { type: Array, default: null},
    options: { type: Array, default: null},
    entries: { type: String, default: "" },
  },
  data() {
    return {
      paginationOption: this.defaultPerPage,
      paginationOptions: [15, 25, 50],
      filterOption: this.option,
      filterOptions: this.options
    };
  },
  methods: {
    searchInput(data) {
      this.$emit("onSearchInput", data);
    },
    perPageChange() {
      this.$emit("perPageChange", this.paginationOption);
    },
    filterSelectChange() {
      this.$emit("filterSelectChange", this.filterOption);
    },
    columnsChange() {
      this.$emit("columnsChange", true);
    },
    filterChange() {
      this.$emit("filterChange", true);
    },
    actionBtnClicked() {
      this.$emit("actionBtnClicked", true);
    }
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
